<template>
  <div class="manage-box-item">
    <p class="word-title">{{title}}<span class="word-text" :style="{'color': color}">{{word}}</span></p>
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: { type: String, default: '标题' },
    word: { type: String, default: '' },
    color: { type: String, default: '#999' },
  },
  setup() {
    
  },
})
</script>
<style lang="scss" scoped>
.manage-box-item {
  margin-top: 30px;
  text-align: left;
  .word-title {
    font-size: 16px;
    font-weight: 400;
    color: $titleColor;
    line-height: 22px;
    margin-bottom: 14px;
  }
  .word-text {
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;
    color: #999;
  }
}
</style>