<template>
  <div class="color-box">
    <section>
      <p>面额背景颜色配置</p>
      <div class="color-block-small">
        <div class="color"></div>
      </div>
      <Sketch v-model="color" @input="getColor" ></Sketch>
    </section>
    <section>
      <p>面额文本颜色配置</p>
      <Sketch v-model="color2" @input="getColor2"></Sketch>
    </section>
  </div>
</template>
<script>
import {Sketch} from 'vue-color'
export default {
  components: { Sketch },
  data() {
    return {
      canvas: undefined,
      color: {
        hex: '#FFB43B',
      },
      color2: {
        hex: '#ffffff',
      },
    }
  },
  mounted() {
    this.canvas = this.$refs.colorCanvas
  },
  methods: {
    getColor(val) {
      this.$emit('get-bgcolor', val)
    },
    getColor2(val) {
      this.$emit('get-textcolor', val)
    }
  },
}
</script>
<style lang="scss" scoped>
.vc-sketch{
  width: 100%;
  padding: 0;
  box-shadow: none;
  ::v-deep .vc-saturation-circle{
    width: 16px;
    height: 16px;
    background-color: #5B8FF9;
  }
}
.color-box{
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  text-align: left;
  display: flex;
  justify-content: space-between;
  &>section{
    width: 40%;
    p{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
</style>