<template>
  <div class="table">
    <div class="search-top">
      <div>
        <div>
          <span>优惠券组：</span>
          <el-select v-model="params.coupon_group_id" filterable>
            <el-option
              v-for="item in groupList"
              :key="item.coupon_group_id"
              :value="item.coupon_group_id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
        <div>
          <span>适用主体：</span>
          <el-select v-model="params.category" @change="getfeeType">
            <el-option value="1" label="商品券"></el-option>
            <el-option value="2" label="包裹券"></el-option>
            <el-option value="3" label="通用券"></el-option>
          </el-select>
        </div>
        <div>
          <span>优惠券费用类型：</span>
          <el-select v-model="params.fee_type_limit">
            <el-option
              v-for="item in feeTypeLimits"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>
          <span>状态：</span>
          <el-select v-model="params.status">
            <el-option :value="null" label="全部"></el-option>
            <el-option value="0" label="未启用"></el-option>
            <el-option value="1" label="已启用"></el-option>
            <el-option value="2" label="已失效"></el-option>
          </el-select>
        </div>
        <!-- <div>
          <span>平台：</span>
          <el-select v-model="params.coupon_group_id">
            <el-option value="1"></el-option>
          </el-select>
        </div> -->
      </div>
      <div>
        <div class="input">
          <span>优惠券名：</span>
          <el-input
            v-model="params.keyword"
            placeholder="请输入优惠券名查询"
          ></el-input>
        </div>
        <div class="input">
          <span>用户id：</span>
          <el-input
            v-model="params.user_id"
            placeholder="请输入用户id查询"
          ></el-input>
        </div>
        <el-button @click="clear">重置</el-button>
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </div>
    <header class="header">
      <div>
        <el-button
          type="primary"
          style="border-radius: 16px"
          @click="bannerEdit"
          >+新建</el-button
        >
      </div>
    </header>
    <DragTable :list-query="list" :header="header" :sort="false">
      <template #state="{ row }">
        <span class="status" :class="{ active: row.status !== 1 }">{{
          status[row.status]
        }}</span>
      </template>
      <template #category="{row}">{{category[row.category]}}</template>
      <template #price="{ row }">
        <span>{{
          `满${row.min_amount}减${parseInt(row.discount_value)}`
        }}</span>
      </template>
      <template #receive_time="{ row }">
        <span>{{
          `${$dayjs(row.receive_start * 1000).format("YYYY-MM-DD")}~${$dayjs(
            row.receive_end * 1000
          ).format("YYYY-MM-DD")}`
        }}</span>
      </template>
      <template #usetime="{ row }">
        <span v-if="row.days">{{ `领取后${row.days}天` }}</span>
        <span v-else-if="row.use_start">{{
          `${$dayjs(row.use_start * 1000).format("YYYY-MM-DD")}~${$dayjs(
            row.use_end * 1000
          ).format("YYYY-MM-DD")}`
        }}</span>
        <span v-else>无限制</span>
      </template>
      <template #createtime="{ row }">
        <span>{{
          $dayjs(row.created_at * 1000).format("YYYY-MM-DD HH:mm:ss")
        }}</span>
      </template>
      <template #control="{ row }">
        <div class="control">
          <span v-if="row.status !== 2" @click="bannerEdit(row, 'edit')">编辑</span>
          <span @click="bannerEdit(row, 'copy')">复制</span>
          <span v-if="row.status === 0" @click="open(row)">开启</span>
          <span @click="detail(row)">详情</span>
          <span v-if="row.status === 1" @click="grant(row)">手动发放</span>
          <span v-if="row.status === 0" @click="del(row)">删除</span>
          <span v-if="row.status !== 2" @click="disable(row)">强制失效</span>
        </div>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
    <coupon-detail v-model="visible" :info="couponInfo"></coupon-detail>
    <go-dialog v-model="grantVisible" width="584px" height="386px">
      <div class="grant-box">
        <p class="title" style="font-size: 20px">手动发放</p>
        <textarea
          v-model="grantContent"
          placeholder="手动输入请用回车换行隔开"
          class="grant-content"
        ></textarea>
        <footer style="margin-top: 24px; display: flex; align-items: center">
          <div class="grant-input">
            <div>导入UID(Excel)</div>
            <input ref="file" type="file" @change="getFile" />
          </div>
          <el-button type="primary" @click="send">发放</el-button>
          <el-button type="primary" @click="download">下载模板</el-button>
        </footer>
      </div>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import couponDetail from "@views/marketCenter/couponManager/coupon/CouponDetail";

export default defineComponent({
  components: { couponDetail },
  setup(props, { root, refs }) {
    const visible = ref(false);
    const grantVisible = ref(false);
    const grantContent = ref("");
    const groupList = ref([]);
    const couponInfo = reactive({ data: {} });
    const total = ref(10);
    const status = {
      0: "未启用",
      1: "已启用",
      2: "已失效",
    };
    const category = {
      1: '商品券',
      2: '包裹券',
      3: '通用券'
    }
    const params = reactive({
      coupon_group_id: undefined,
      page: 1,
      per_page: 10,
      category: undefined,
      fee_type_limit: undefined,
      keyword: undefined,
      is_show_page: 1,
      status: null,
    });
    watch(visible,val=>{
      if(!val) {
        getList()
      }
    })
    const feeTypeLimits = ref([]);
    const header = [
      { name: "优惠券id", fixed: "left", value: "coupon_id", width: 105 },
      {
        name: "优惠券组",
        value: "coupon_group_name",
        fixed: "left",
        width: 140,
      },
      { name: "优惠券名", value: "name", width: 180 },
      { name: "适用主体", width: 103, slot: 'category' },
      {
        name: "面额（日元）",
        value: "redirect_url",
        slot: "price",
        width: 140,
      },
      {
        name: "领取时间段",
        value: "receive_start",
        slot: "receive_time",
        width: 260,
      },
      { name: "使用时间", value: "use_start", slot: "usetime", width: 260 },
      { name: "创建时间", value: "created_at", slot: "createtime", width: 220 },
      { name: "创建人", value: "admin_name" },
      {
        name: "状态",
        value: "state",
        fixed: "right",
        width: 140,
        slot: "state",
      },
      { name: "操作", value: "", fixed: "right", slot: "control", width: 290 },
    ];
    const list = reactive({ data: [] });
    const getfeeType = (num) => {
      feeTypeLimits.value = []
      params.fee_type_limit = undefined
      if(!num) return
      root.$axios
        .get("/coupons/feeTypeLimits", {
          params: { category: num },
        })
        .then((res) => {
          feeTypeLimits.value = res.data;
        })
        
    };
    const getList = () => {
      root.$axios
        .get("/coupons/coupons", {
          params,
        })
        .then((res) => {
          list.data = res.data.result;
          total.value = res.data.pagination.total;
        });
    };
    const getGroup = () => {
      try {
        root.$axios
          .get("/coupons/groups")
          .then((res) => {
            groupList.value = res.data.result;
          });
      } catch (error) {
        root.$message.error(error)
      }
    };
    getGroup();
    const grant = (data) => {
      grantVisible.value = true;
      couponInfo.data = data
      grantContent.value = data.content;
    };
    const bannerEdit = async (data, type) => {
      couponInfo.data = {};
      const obj = Object.assign({}, data);
      if (type === "edit" || type === "copy") {
        couponInfo.data = obj;
      }
      if (type === "copy") {
        couponInfo.data.coupon_id = undefined;
        couponInfo.data.name = undefined;
        couponInfo.data.status = 0
      }
      visible.value = true;
    };
    const del = async (data) => {
      try {
        const confirm = await root.$goDialog("确定要删除吗？");
        if (!confirm) return;
        const res = await root.$axios.delete(
          "/coupons/coupons/" + data.coupon_id
        );
        if (res.code === 1000) {
          root.$message.success("删除成功");
          getList();
        }
      } catch (error) {
        root.$message.error(error)
      }
    };
    const detail = (data) => {
      root.$router.push(
        `/market/couponManager/couponChild?id=${data.coupon_id}&name=${data.name}&groupName=${data.coupon_group_name}`
      );
    };
    const open = async (data) => {
        const confirm = await root.$goDialog(
          `确定要启用吗？`
        );
        if (!confirm) return;
        data.status = 1
        data.discount_value = Number(data.discount_value)
        root.$axios.patch(
          "/coupons/coupons/" + data.coupon_id,
          data
        )
        .then(res=>{
          root.$message.success(res.msg);
          getList();
        })
        
    };
    const clear = () => {
      for (let key in params) {
        params[key] = undefined;
      }
      params.coupon_group_id = undefined;
      params.page = 1;
      params.per_page = 10;
      params.category = undefined;
      params.is_show_page = 1
      params.status = null;
      getList();
    };
    const getFile = () => {
      const file = refs.file.files[0];
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const formData = new FormData()
      formData.append('excel_file', file)
      root.$axios.post('/coupons/importUserIds',formData,config)
      .then(res=>{
        if(res.code === 1000) {
          const arr = res.data.user_ids_str.split(',')
          grantContent.value = arr.join('\n')
        }
      })
      
    };
    const download = () => {
      try {
        root.$axios.get(`/coupons/downloadTemplate/importUserIds`)
        .then(res=>{
          const a = window.document.createElement('a')
          a.href= root.$store.state.CMS.url+res.data.file_path
          a.click()
        })
      } catch (error) {
        console.error(error)
      }
    }
    const send = async () =>{
      if(!grantContent.value || !grantContent.value.trim()) {
        root.$message.warning('请输入用户id')
        return
      }
      const arr = grantContent.value.split('\n')
      const confirm = await root.$goDialog('确定要发送给用户吗？')
      if(!confirm) return
      root.$axios.post(`/coupons/coupons/${couponInfo.data.coupon_id}/grantCoupon`,{
        user_ids: arr.join(',')
      })
      .then(res=>{
        if (res.code === 1000) {
          root.$message.success(res.msg);
          grantVisible.value = false;
          grantContent.value = undefined
        } else {
          root.$message.error(res.msg);
        }
      })
      
    }
    const disable = async(row) => {
      const confirm = await root.$goDialog('是否失效优惠券？！')
      if(!confirm) return
      try {
        root.$axios.put('/coupons/coupons/'+row.coupon_id+'/forceInvalidAll')
        .then(res=>{
          if(res.code === 1000) {
            root.$message.success(res.msg)
            getList()
          } else {
            root.$message.error(res.msg)
          }
        })
      } catch (err) {
        root.$message.error(err)
      }
    }
    const search = () => {
      params.page = 1
      getList()
    }
    const handleSizeChange = (size) => {
      params.per_page = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    getList();
    return {
      status,
      total,
      visible,
      grantVisible,
      grantContent,
      params,
      couponInfo,
      groupList,
      list,
      header,
      feeTypeLimits,
      category,
      send,
      search,
      download,
      grant,
      getFile,
      detail,
      getList,
      del,
      clear,
      open,
      bannerEdit,
      disable,
      handleSizeChange,
      handleCurrentChange,
      getfeeType
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin circle() {
  position: absolute;
  content: " ";
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: $safeColor;
}
.table {
  width: 100%;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
}
.search-top {
  & > div {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: flex-end;
}
.status {
  color: $safeColor;
  position: relative;
  color: $safeColor;
  padding-left: 13px;
  &::after {
    @include circle();
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &.active {
    color: $wordColor;
    &::after {
      background-color: $wordColor;
    }
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.table-img {
  width: 100%;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.grant-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grant-content {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.grant-input {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 180px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #537eff;
  line-height: 32px;
  margin-right: 15px;
  div {
    font-size: 16px;
    color: #537eff;
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
</style>
