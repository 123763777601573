import { render, staticRenderFns } from "./ManageItem.vue?vue&type=template&id=4e2b2f03&scoped=true&"
import script from "./ManageItem.vue?vue&type=script&lang=js&"
export * from "./ManageItem.vue?vue&type=script&lang=js&"
import style0 from "./ManageItem.vue?vue&type=style&index=0&id=4e2b2f03&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2b2f03",
  null
  
)

export default component.exports