<template>
  <div v-if="show" class="coupon-detail">
    <div class="coupon-detail-box">
      <i class="close el-icon-close" @click="close(false)"></i>
      <h3 class="coupon-detail-title">优惠券</h3>
      <div class="coupon-detail-content">
        <section class="left">
          <ManageItem title="优惠券组">
            <el-select
              v-model="params.coupon_group_id"
              :disabled="(this.params.coupon_id && true) || this.params.coupon_id === 0"
              placeholder="请输入优惠券组"
              allow-create
              filterable
            >
            <el-option v-for="item in groupList" :key="item.coupon_group_id" :value="item.coupon_group_id" :label="item.name" ></el-option>
            </el-select>
          </ManageItem>
          <ManageItem title="优惠券名">
            <el-input
              v-model="params.name"
              :disabled="(this.params.coupon_id && true) || this.params.coupon_id === 0"
              placeholder="请输入优惠券名"
            ></el-input>
          </ManageItem>
          <ManageItem title="适用主体">
            <el-select v-model="params.category" @change="handleFee">
              <el-option :value="1" label="商品券"></el-option>
              <el-option :value="2" label="包裹券"></el-option>
              <el-option :value="3" label="通用券"></el-option>
            </el-select>
          </ManageItem>
          <ManageItem
          v-if="params.category"
            title="优惠券费用类型"
            color="#F14F46"
            word="不选择的话默认无限制"
          >
            <el-select v-model="params.fee_type_limit" multiple collapse-tags  @change="handleFeeType">
              <el-option v-for="item in feeTypeLimits" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </ManageItem>
          <ManageItem title="优惠券面额">
            <div class="coupon-price-list">
              <div class="coupon-price-item">
                <p>满(日元)<span>可为0，为0则默认为无门槛</span></p>
                <el-input v-model="params.min_amount" type="number" placeholder="0"></el-input>
              </div>
              <div class="coupon-price-item">
                <p>减(日元)</p>
                <el-input v-model="params.discount_value" type="number" placeholder="请输入折扣值"></el-input>
              </div>
              <div class="coupon-price-item">
                <p>商品原价限制</p>
                <el-input v-model="params.product_amount_limit" type="number" placeholder="请输入单价"></el-input>
              </div>
            </div>
          </ManageItem>
          <ManageItem title="领取时间">
            <el-date-picker
              v-model="dateTime"
              :disabled="params.status === 1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="请选择时间"
            ></el-date-picker>
          </ManageItem>
          <ManageItem title="使用时间">
            <div style="margin-bottom:10px;">
              <el-radio v-model="number" label="1" class="radio" :disabled="params.status === 1"
                >固定时间段</el-radio
              >
              <el-radio v-model="number" label="2" class="radio" :disabled="params.status === 1"
                >领取后时长</el-radio
              >
            </div>
            <el-date-picker
              v-if="number == 1"
              v-model="date_time"
              :disabled="params.status === 1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="请选择时间"
            ></el-date-picker>
            <el-input
              v-if="number == 2"
              type="number"
              :disabled="params.status === 1"
              v-model="params.days"
              placeholder="请输入领取后时长"
            ></el-input>
            <p v-if="number == 1" style="color:red;">*不选则自动添加默认时间</p>
          </ManageItem>
        </section>
        <section class="right">
          <!-- <ManageItem title="商品类别">
            <el-select v-model="params.product_limit">
            </el-select>
          </ManageItem> -->
          <ManageItem v-if="params.category == 1" title="站点限制">
            <el-select v-model="params.site_limit" :disabled="params.status === 1" placeholder="不选则无限制" multiple collapse-tags>
              <el-option v-for="item in siteList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </ManageItem>
          <ManageItem v-if="params.category == 2" title="物流限制">
            <el-select v-model="params.express_limit" :disabled="params.status === 1" placeholder="不选则无限制" multiple collapse-tags>
              <el-option v-for="item in expressList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </ManageItem>
          <div style="margin-top:20px;">
            <color @get-bgcolor="getBg" @get-textcolor="getText"></color>
          </div>
          <p class="preview">预览</p>
          <div class="coupon-demo">
            <div class="left">
              <div>
                <p class="left-title">{{params.name || '优惠券名'}}</p>
                <p class="left-word">{{getTime(number)}}</p>
              </div>
              <p>使用说明<i class="el-icon-arrow-down"></i></p>
            </div>
            <div class="right" :style="{'color': params.text_color,'background-color':params.bg_color}">
              <p>{{status[params.category]}}</p>
              <p class="price">{{params.discount_value}}</p>
              <p>日元</p>
              <p class="small-price">{{params.min_amount === 0 ? '无门槛' : `满${params.min_amount}日元可用`}}</p>
            </div>
          </div>
        </section>
      </div>
      <footer class="coupon-detail-footer">
        <el-button @click="close(false)">取消</el-button>
        <el-button type="primary" @click="debounce(confirm, 1000)">确认</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import ManageItem from "@/components/commonUI/ManageItem";
import color from '@/components/marketCenter/coupon/color_block'

export default {
  components: { ManageItem, color },
  model: {
    prop: 'show',
    event: 'toggle'
  },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default:()=>{} }
  },
  data() {
    return {
      params: {
        coupon_group_id: undefined,
        name: undefined,
        category: undefined,
        fee_type_limit: undefined,
        min_amount: 0,
        discount_value: undefined,
        product_amount_limit: undefined,
        user_receive_limit: 0,
        receive_start: undefined,
        receive_end: undefined,
        use_start: this.$dayjs().startOf('day').unix(),
        use_end: this.$dayjs('2050-12-31 23:59:59').unix(),
        days: undefined,
        product_limit: [-9999],
        site_limit: undefined,
        express_limit: undefined,
        bg_color: '#FFB43B',
        text_color: '#ffffff',
      },
      number: '1',
      dateTime: undefined,
      date_time: [this.$dayjs().startOf('day'),this.$dayjs('2050-12-31 23:59:59')],
      feeTypeLimits: [],
      siteList: [],
      expressList: [],
      groupList: [],
      status: {
        1: '商品券',
        2: '包裹券',
        3: '通用券'
      }
    };
  },
  watch: {
    dateTime(val) {
      if(val) {
        this.params.receive_start = this.$dayjs(val[0]).startOf('day').unix()
        this.params.receive_end = this.$dayjs(val[1]).endOf('day').unix()
      } else {
        this.params.receive_start = undefined
        this.params.receive_end = undefined
      }
    },
    date_time(val) {
      if(val) {
        this.params.use_start = this.$dayjs(val[0]).startOf('day').unix()
        this.params.use_end = this.$dayjs(val[1]).endOf('day').unix()
      } else {
        this.params.use_start = this.$dayjs().startOf('day').unix()
        this.params.use_end = this.$dayjs('2050-12-31 23:59:59').unix()
      }
    },
    info: {
      handler({data}) {
        if(!data.discount_value || !this.show) return
        this.params = Object.assign({}, data)
        this.params.discount_value = Number(data.discount_value)
        this.getfeeType(this.params.category)
        if(this.params.fee_type_limit.includes(-9999)){
          this.params.fee_type_limit = []
        }
        if(data.receive_start) {
          this.dateTime = [this.$dayjs(data.receive_start*1000),this.$dayjs(data.receive_end*1000)]
        } else {
          this.dateTime = undefined
        }
        if(data.days) {
          this.number = '2'
          return
        }
        if(data.use_start){
          this.date_time = [this.$dayjs(data.use_start*1000),this.$dayjs(data.use_end*1000)]
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getGroup()
    this.getSiteLimits()
    this.getExpressLimits()
  },
  methods: {
    handleFeeType() {
      if(this.params.fee_type_limit.includes(-9999)) {
        this.params.fee_type_limit.splice(this.params.fee_type_limit.indexOf(-9999),1)
      }
    },
    getTime(number) {
      if(number === '1' && this.date_time) {
        return `有效期至${this.params.use_end && this.$dayjs(this.params.use_end * 1000).format('YYYY-MM-DD HH:mm:ss')}` || '有效期至'
      } else if(number === '1' && !this.date_time) {
        return `无限制`
      } else {
        return `根据用户领取时间计算`
      }
    },
    getfeeType(num){
      this.$axios.get(this.$store.state.CMS.url+'/coupons/feeTypeLimits', {
        params: {category: num}
      })
      .then(res=>{
        if(res.code === 1000) {
          this.feeTypeLimits = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getSiteLimits() {
      this.$axios.get(this.$store.state.CMS.url+'/coupons/siteLimits')
      .then(res=>{
        if(res.code === 1000) {
          this.siteList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
      
    },
    getExpressLimits() {
      this.$axios.get(this.$store.state.CMS.url+'/coupons/expressLimits')
      .then(res=>{
        if(res.code === 1000) {
          this.expressList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
      
    },
    handleFee(val) {
      this.getfeeType(val)
    },
    getGroup() {
      try {
        this.$axios.get(this.$store.state.CMS.url+'/coupons/groups')
        .then(res=>{
          this.groupList = res.data.result
        })
      } catch (error) {
        console.error(error)
      }
    },
    getBg(val) {
      this.params.bg_color = val.hex
    },
    getText(val) {
      this.params.text_color = val.hex
    },
    async close(flag) {
      if(!flag) {
        const confirm = await this.$goDialog('确定要取消吗？')
        if(!confirm) return
      }
      for(let key in this.params) {
        this.params[key] = ''
      }
      this.params.product_limit = [-9999]
      this.params.bg_color = '#FFB43B',
      this.params.text_color = '#ffffff',
      this.params.min_amount = 0
      this.number = '1'
      this.dateTime = 0
      this.date_time = [this.$dayjs().startOf('day'),this.$dayjs('2050-12-31 23:59:59')]
      delete this.params.coupon_id
      this.$emit('toggle', false)
    },
    async confirm() {
      if(!this.params.coupon_group_id) {
        this.$message.warning('请选择优惠券组')
        return
      }
      if(!this.params.name || !this.params.name.trim()) {
        this.$message.warning('请输入优惠券名')
        return
      }
      if(!this.params.category) {
        this.$message.warning('请选择适用主体')
        return
      }
      if(!this.params.min_amount && this.params.min_amount != 0) {
        this.$message.warning('请输入优惠券最低使用金额')
        return
      }
      if(this.params.min_amount < 0) {
        this.$message.warning('优惠券最低使用金额不能小于0')
        return
      }
      if(!this.params.discount_value) {
        this.$message.warning('请输入折扣值')
        return
      }
      if(this.params.discount_value < 0) {
        this.$message.warning('折扣值不能小于0')
        return
      }
      if(!this.params.product_amount_limit && this.params.product_amount_limit != 0) {
        this.$message.warning('请输入商品原价限制')
        return
      }
      if(this.params.product_amount_limit < 0) {
        this.$message.warning('商品原价不能小于0')
        return
      }
      if(!this.params.receive_start) {
        this.$message.warning('请选择领取时间')
        return
      }
      if(this.number === '2' && this.params.days == 0) {
        this.$message.warning('使用时间不能为0')
        return
      }
      if(this.params.category === '1') {
        this.params.express_limit = [-9999]
      }
      if(this.params.category === '2') {
        this.params.site_limit = [-9999]
      }
      if(this.params.category === '3') {
        this.params.site_limit = [-9999]
        this.params.express_limit = [-9999]
      }
      if(!this.params.fee_type_limit || this.params.fee_type_limit.length === 0) {
        this.params.fee_type_limit = [-9999]
      }
      if(this.params.status != 1 && this.params.receive_start < this.$dayjs().startOf('day').unix()) {
        this.$message.warning('领取开始时间不能小于今天')
        return
      }
      if(this.number === '1' && this.params.use_start < this.params.receive_start) {
        this.$message.warning('使用开始时间不能小于领取开始时间')
        return
      }
      if(this.number === '2') {
        this.params.use_start = undefined
        this.params.use_end = undefined
      }
      if(this.number === '1') {
        this.params.days = undefined
      }
      if(this.params.coupon_id || this.params.coupon_id === 0) {
        this.$axios.patch('/coupons/coupons/'+this.params.coupon_id, this.params)
        .then(res=>{
          this.$message.success(res.msg)
          this.close(true)
        })
        
      } else {
        this.$axios.post('/coupons/coupons', this.params)
        .then(res=>{
          this.$message.success(res.msg)
          this.close(true)
        })
        
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.coupon-detail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
}
.coupon-detail-box {
  padding: 14px 25px;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  width: 100%;
  height: 90%;
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  & > .close {
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 14px;
    top: 9px;
    color: $lightColor;
  }
  .coupon-detail-title {
    font-size: 20px;
    text-align: left;
  }
}
.coupon-detail-content {
  display: flex;
  position: relative;
  flex: auto;
  overflow: hidden;
  &::after {
    position: absolute;
    content: " ";
    right: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $lineColor;
  }
  & > section {
    width: 100%;
    padding-top: 26px;
    padding-right: 50px;
    overflow-y: auto;
    &.right{
      padding-left: 50px;
    }
  }
  .radio {
    margin-right: 80px;
  }
}
.coupon-price-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .coupon-price-item {
    width: 45%;
    & > p {
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
      margin-top: 5px;
      & > span {
        margin-left: 8px;
        font-size: 12px;
        color: #f14f46;
      }
    }
  }
}
.preview{
  font-size: 16px;
  color: #333;
  margin-top: 46px;
}
.coupon-demo{
  display: flex;
  width: 313px;
  height: 110px;
  border: 1px solid #ccc;
  .left{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 14px;
    .left-title{
      font-size: 16px;
      color: #333;
    }
    .left-word{
      font-size: 12px;
      color: #999;
    }
  }
  .right{
    height: 100%;
    width: 110px;
    flex-shrink: 0;
    background-color: #fff;
    text-align: center;
    padding: 12px;
    font-size: 12px;
    .price{
      font-size: 28px;
    }
    .small-price{
      font-size: 10px;
    }
  }
}
.manage-box-item{
  ::v-deep .el-date-editor{
    width: 380px;
  }
}
.coupon-detail-footer {
  height: 68px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .el-button {
    width: 120px;
    height: 36px;
    margin-left: 24px;
  }
}
</style>